import React, { useEffect } from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import "./CalendarPage.scss";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { updateNavLink } from "stateMgmt/daaSlice";
// import { selectapp } from "stateMgmt/appSlice";
import Typography from "@mui/material/Typography";
import TableGenerator from "factories/TableGenerator";
import CalendarPageData from "./CalendarPageData.json";
import PropTypes from "prop-types";

const CalendarPage = (props) => {
  const dispatch = useDispatch();
  // const theme = useSelector(selectapp).theme;

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/CalendarPage/CalendarPage.js",
    title: "Calendar",
  });

  const leavePage = () => {
    dispatch(updateNavLink({ calllingLink: "CAL" }));
  };

  useEffect(() => {
    document.getElementById("page-heading").focus();
  }, []);

  const pageContents = (
    // DMA-316 Update Q2 2020 release date.
    <div className="row" id="Calendar_page-top" data-testid="Calendar_Page">
      <div className="col-lg-12 Calendar_page">
        <Typography variant="h1" id="page-heading" role="heading" tabIndex={0}>
          Data Publish Calendar
        </Typography>

        <div
          role="table"
          tabIndex={0}
          aria-label="Calendar of Spotlight dates of publication."
          onBlur={() => leavePage()}
        >
          <TableGenerator
            useSubHeader={false}
            headerData={CalendarPageData.headerData}
            subHeaderData={CalendarPageData.subHeaderData}
            rowData={CalendarPageData.rowData}
            headerAlign={"center"}
          />
        </div>
      </div>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="Calendar_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

CalendarPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default CalendarPage;
