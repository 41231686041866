import React from "react";
import Grid from "@mui/material/Grid2";
// import { selectapp } from "stateMgmt/appSlice";
// import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/CDI/Timeline.png";
import "../CountryPages.scss";

const Timeline = () => {
  //   const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="timeline"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title cdi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>Community-Led Monitoring (CLM) Timeline</header>
        </Grid>
        <Grid container size={12} sx={storyBody}>
          <Grid size={12}>
            <Typography variant="h2">
              Community-Led Organizations (CLOs) engage to remove barriers
              facing PLHIV to
              <br />
              improve Côte d’Ivoire’s response to reach HIV 95-95-95.
            </Typography>
          </Grid>
          <Grid size={12}>
            <div className="image-frame">
              <img src={image1} style={{ width: "100%" }} alt="" />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Timeline;
