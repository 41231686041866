import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const Access = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="access"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Community Access to and Use of Data in Vietnam
          </header>
        </Grid>
        <Grid container size={12} sx={storyBody}>
          <Grid size={12}>
            <Typography variant="h2" tabIndex={0}>
              Community members actively participate in data generation and
              dissemination.
            </Typography>
            <Typography>
              <List
                sx={{
                  pl: 5,
                  pr: 5,
                  pt: 0,
                  color: theme.color,
                  listStyleType: "disc",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  They serve as interviewees, interviewers, researchers, data
                  collectors, and M&E officers and managers.
                </ListItem>
                <ListItem tabIndex={0}>
                  This active involvement allows for a comprehensive
                  understanding of the community&apos;s needs and challenges.
                </ListItem>
              </List>
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant="h2" tabIndex={0}>
              Communities have access to different types of data.
            </Typography>

            <Typography>
              <List
                sx={{
                  pl: 5,
                  pr: 5,
                  pt: 0,
                  color: theme.color,
                  listStyleType: "disc",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  Program data is made available through program reports,
                  enabling communities to review their performance and design
                  effective interventions and communication campaigns.
                </ListItem>
                <ListItem tabIndex={0}>
                  Epidemic data is publicly shared by the government at
                  different levels, and empowers communities to assess their
                  response to epidemics and identify gaps in service delivery.
                </ListItem>
                <ListItem tabIndex={0}>
                  Community access to and use of data in Vietnam plays a crucial
                  role in promoting accountability, improving interventions, and
                  enhancing communication strategies of the community in the
                  national sustainable HIV response.
                </ListItem>
              </List>
            </Typography>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Access;
