import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Dreams/map.png";

import "../CountryPages.scss";

const Empowered = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="empowered"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title dreams-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Determined, Resilient, Empowered, AIDS-free, Mentored, Safe
          </header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "5vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid size={{ sm: 12, md: 12, lg: 5 }}>
              <Typography variant="body1">
                <List
                  sx={{
                    px: 10,
                    lineHeight: "1.2",
                    color: theme.color,
                    listStyleType: "disc",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    PEPFAR announced the flagship{" "}
                    <font className="hilite">DREAMS program</font> on World AIDS
                    Day 2014.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    Now implemented in{" "}
                    <font className="hilite">15 countries</font>, DREAMS
                    provides a{" "}
                    <font className="hilite">comprehensive package</font> of
                    core interventions to address key factors that make
                    adolescent girls and young women (AGYW) particularly
                    <font className="hilite"> vulnerable to HIV</font>.
                    <List
                      sx={{
                        pl: 10,
                        pt: 3,
                        lineHeight: "1.0",
                        color: theme.color,
                        listStyleType: "circle",
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      <ListItem tabIndex={0}>
                        These include lack of access to comprehensive health
                        knowledge, family dynamics and structural barriers
                        (i.e., gender-based violence, exclusion from economic
                        opportunities, and a lack of access to secondary
                        school).
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid size={{ sm: 12, md: 12, lg: 7 }}>
              <div className="image-frame">
                <img
                  tabIndex={0}
                  src={image}
                  style={{ width: "85%" }}
                  alt="DREAMS is being implemented across 15 countries, representing very diverse settings."
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Empowered;
