import React, { useState, useEffect } from "react";
import "./LandingPage.scss";
import CarouselRow from "./CarouselRow/CarouselRow";
import ProgramResultsFiguresRow from "./ProgramResultsFiguresRow/ProgramResultsFiguresRow";
import Footer from "components/Footer/Footer";
import jsonFooterConfig from "assets/configs/footer-config.json";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { isMobileOnly } from "react-device-detect";
import { isPortrait } from "common";

const mainContent = (props) => {
  const theme = useSelector(selectapp).theme;
  const appContext = useSelector(selectapp).context;
  const [isHidden, setIsHidden] = useState(false);
  const [open, setOpen] = useState(true);

  let mainContentCssClasses =
    "landing-page__main landing-page__main__left-margin left-margin-wide-monitors";
  if (props.isSidebarCollapsed) {
    mainContentCssClasses = mainContentCssClasses.replace(
      "landing-page__main__left-margin",
      "landing-page__main__closed-margin",
    );
    mainContentCssClasses = mainContentCssClasses.replace(
      "left-margin-wide-monitors",
      "",
    );
  }

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
    setOpen(isMobileOnly && isPortrait);
  });

  // FORMAT: landing-page__container (HTML)
  return (
    <div
      id="mainContent"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
      className={mainContentCssClasses}
      role="main"
      tabIndex={-1}
    >
      <Collapse in={open}>
        <Alert
          severity="warning"
          sx={{ fontSize: "1.0rem", fontWeight: 800 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          }
        >
          This site is best viewed in landscape mode.
        </Alert>
      </Collapse>

      <div className="container-fluid landing-page__container">
        <div>
          <ProgramResultsFiguresRow />
        </div>

        <div>
          <CarouselRow />
        </div>

        <div className="row" id="footer_row_iata">
          <div
            className="col-12"
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              fontSize: "1.3vh",
            }}
          >
            <Box
              sx={{ fontSize: "0.6rem" }}
              tabIndex={isHidden ? -1 : 1}
              role="note"
            >
              Unless otherwise noted, the content, data, documentation, code,
              and related materials on data.pepfar.gov is public domain and made
              available with a{" "}
              <a
                href="http://creativecommons.org/publicdomain/zero/1.0/legalcode"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.linkColor }}
                aria-hidden={isHidden}
                tabIndex={isHidden ? "-1" : 1}
              >
                Creative Commons CC0 1.0 Universal
              </a>{" "}
              dedication and license-free (per US Code{" "}
              <a
                href="http://www.gpo.gov/fdsys/pkg/USCODE-2010-title17/html/USCODE-2010-title17-chap1-sec105.htm"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.linkColor }}
                aria-label="Title 17 section 105 subject matter of copyright"
                aria-hidden={isHidden}
                tabIndex={isHidden ? "-1" : 1}
              >
                17 USC § 105
              </a>
              ). Data.pepfar.gov waive all rights to the work worldwide under
              copyright law, including all related and neighboring rights, to
              the extent allowed by law. You can copy, modify, distribute, and
              perform the work, even for commercial purposes, all without asking
              permission. Citation of data.pepfar.gov as a source of the data is
              appreciated.
            </Box>
          </div>
        </div>

        <div>
          <Footer footerConfig={jsonFooterConfig} />
        </div>
      </div>
    </div>
  );
};

export default mainContent;
