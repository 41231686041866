import React, { useState, useEffect } from "react";
import { Route, NavLink, Routes, useNavigate } from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage";
import Http404NotFoundPage from "./Http404NotFoundPage/Http404NotFoundPage";
import * as common from "common";
import NewLibraryPage from "./LibraryPage/NewLibraryPage";
import DatasetsPage from "./Datasets/DatasetsPage";
import sidebarItems from "assets/data/sidebarItems";
import DashboardPage from "./DashboardPage/DashboardPage";
import EvaluationsPage from "./EvaluationsPage/EvaluationsPage";
import AdditionalDataPage from "./AdditionalDataPage/AdditionalDataPage";
import DataSourcesPage from "./DataSourcesPage/DataSourcesPage";
import CalendarPage from "./CalendarPage/CalendarPage";
import ContactUsPage from "./ContactUsPage/ContactUsPage";
import CopyrightPage from "./CopyrightPage/CopyrightPage";
import InfoDisclaimerPage from "./InfoDisclaimerPage/InfoDisclaimerPage";
import FAQsPage from "./FAQsPage/FAQsPage";
import GlossaryPage from "./GlossaryPage/GlossaryPage";
import MapPage1 from "../DAAMapPage/MapPage1";
import { v4 as uuid } from "uuid";
import IconButton from "@mui/material/IconButton";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useSelector } from "react-redux";
import { selectTheme, selectContext } from "stateMgmt/appSlice";
import { selectNavLink, selectFooterLink } from "../../stateMgmt/daaSlice";
import "./MainContentArea.scss";

// START: SidebarArea styles
const divStyle = {
  height: "100%",
  width: "199px",
  display: "table",
};
// END: SidebarArea styles

/* This component is actually a Bootstrap Container, which itself contains only 1
    Bootstrap row, to hold the Sidebar and the MainContentArea of website.
    Reference: https://getbootstrap.com/docs/4.0/layout/overview/
*/
function MainContentArea() {
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  const appContext = useSelector(selectContext);
  const currentNavLink = useSelector(selectNavLink);
  const currentFooterLink = useSelector(selectFooterLink);
  const [isHidden, setIsHidden] = useState(false);

  const urlToUse = common.useMicrostrategyDev()
    ? "externalLinkDevURL"
    : "externalLinkProdURL";
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebarCollapsed = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const collapseSidebarIcons = () => {
    return isSidebarCollapsed ? (
      <span
        data-testid="side-btn-2"
        className="sidebar-collapse-span__right"
        tabIndex={-1}
      >
        <IconButton
          // aria-label="closeSidebar"
          tabIndex={-1}
          aria-disabled={true}
          onClick={toggleSidebarCollapsed}
          size={"large"}
          sx={{ color: "#be0000", padding: "5px" }}
        >
          {<ArrowCircleRightIcon sx={{ width: "2.0vmax" }} />}
        </IconButton>
      </span>
    ) : (
      <span
        className="sidebar-collapse-span__left"
        data-testid="side-btn"
        tabIndex={-1}
      >
        <IconButton
          // aria-label="openSidebar"
          tabIndex={-1}
          aria-disabled={true}
          onClick={toggleSidebarCollapsed}
          size={"large"}
          sx={{ color: "#be0000", padding: "0px" }}
        >
          {<ArrowCircleLeftIcon sx={{ width: "2.0vmax" }} />}
        </IconButton>
      </span>
    );
  };

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  useEffect(() => {
    if (currentNavLink && currentNavLink !== "") {
      if (currentNavLink === "site-start") {
        navigate("/");
      } else {
        document.getElementById(currentNavLink).focus();
      }
    }
  }, [currentNavLink]);

  useEffect(() => {
    if (currentFooterLink && currentFooterLink !== "") {
      if (currentFooterLink === "site-start") {
        navigate("/");
      } else {
        document.getElementById(currentFooterLink).focus();
      }
    }
  }, [currentFooterLink]);

  return (
    <div className="container-fluid container-style" data-testid="MainContent">
      <div className="row row-style" data-testid="sidebar" tabIndex={-1}>
        {/* START: SidebarArea */}
        {collapseSidebarIcons()}
        <nav
          className={
            isSidebarCollapsed
              ? "hidden"
              : "col-md-2 d-none d-md-block navStyles"
          }
          aria-hidden={isHidden}
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.linkColor,
            fontWeight: 800,
          }}
          id="sidebar__nav"
        >
          <div
            role="navigation"
            className="sidebar-sticky"
            style={divStyle}
            // tabIndex={-1}
          >
            {/* START: SideNav */}
            <ul className="nav flex-column">
              {sidebarItems.map((sidebarItem, sidebarItemIndex) =>
                typeof sidebarItem.externalLinkDevURL === "undefined" ? (
                  <li
                    className="nav-item liStyles"
                    style={{
                      color: theme.linkColor,
                      fontWeight: 800,
                    }}
                    key={uuid()}
                  >
                    <NavLink
                      id={sidebarItem.id}
                      style={{
                        color: theme.linkColor,
                        fontWeight: 800,
                      }}
                      className="nav-link"
                      key={uuid()}
                      to={sidebarItem.routePath}
                      end
                      tabIndex={isHidden ? -1 : 2}
                    >
                      <i className={sidebarItem.fontAwesomeIconClasses}></i>
                      {sidebarItem.name}
                    </NavLink>
                  </li>
                ) : (
                  <li
                    className="nav-item liStyles"
                    style={{
                      color: theme.linkColor,
                      fontWeight: 800,
                    }}
                    key={uuid()}
                  >
                    <a
                      id={sidebarItem.id}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={uuid()}
                      className="nav-link"
                      style={{
                        color: theme.linkColor,
                        fontWeight: 800,
                      }}
                      href={sidebarItem[urlToUse]}
                      tabIndex={isHidden ? -1 : 2}
                    >
                      <i
                        className={sidebarItem.fontAwesomeIconClasses}
                        style={{
                          color: theme.linkColor,
                          fontWeight: 800,
                        }}
                      ></i>
                      {sidebarItem.name}
                    </a>
                  </li>
                ),
              )}
            </ul>
            {/* END: SideNav */}
          </div>
        </nav>
        {/* END: SidebarArea */}

        <Routes>
          <Route
            path="/"
            element={<LandingPage isSidebarCollapsed={isSidebarCollapsed} />}
          />

          <Route
            path="/library"
            element={<NewLibraryPage isSidebarCollapsed={isSidebarCollapsed} />}
          />

          <Route
            path="/datasets"
            element={<DatasetsPage isSidebarCollapsed={isSidebarCollapsed} />}
          />

          <Route
            path="/evaluations"
            element={
              <EvaluationsPage isSidebarCollapsed={isSidebarCollapsed} />
            }
          />
          <Route
            path="/additionalData"
            element={
              <AdditionalDataPage isSidebarCollapsed={isSidebarCollapsed} />
            }
          />
          <Route
            path="/dataSources"
            element={
              <DataSourcesPage isSidebarCollapsed={isSidebarCollapsed} />
            }
          />
          <Route
            path="/calendar"
            element={<CalendarPage isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/faqs"
            element={<FAQsPage isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/glossary"
            element={<GlossaryPage isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/contactus"
            element={<ContactUsPage isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/copyright"
            element={<CopyrightPage isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/infodisclaimer"
            element={
              <InfoDisclaimerPage isSidebarCollapsed={isSidebarCollapsed} />
            }
          />
          <Route
            path="/daaMap1"
            element={<MapPage1 isSidebarCollapsed={isSidebarCollapsed} />}
          />
          <Route
            path="/Pepfar20Years"
            element={
              <DashboardPage
                isSidebarCollapsed={isSidebarCollapsed}
                dashboardName="Pepfar20Years"
              />
            }
          />

          <Route
            element={
              <Http404NotFoundPage isSidebarCollapsed={isSidebarCollapsed} />
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default MainContentArea;
