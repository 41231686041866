import React, { useEffect } from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import { useDispatch } from "react-redux";
import { updateFooterLink } from "stateMgmt/daaSlice";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import logger from "../../../services/LoggingService";

const CopyrightPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("Copyright_page-top").focus();
  }, []);

  const leavePage = () => {
    logger.debug("  leaving copyright page");
    dispatch(updateFooterLink({ calllingLink: "Copyright" }));
  };

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/CopyrightPage/CopyrightPage.js",
    title: "Copyright",
  });

  const pageContents = (
    <div>
      <h1 id="Copyright_page-top" data-testid="Copyright_Page" tabIndex={0}>
        Copyright Information
      </h1>
      <p role="note" tabIndex={0}>
        Unless a copyright is indicated, information on State Department
        websites is in the public domain and may be copied and distributed
        without permission. Citation of the U.S. State Department as source of
        the information is appreciated.
      </p>
      <p role="note" tabIndex={0}>
        If a copyright is indicated on a photo, graphic, or other material,
        permission to copy these materials must be obtained from the original
        source. For photos without captions or with only partial captions, hold
        your cursor over the photo to view the &quot;alt tag&quot; for any
        copyright information. Please note that many photos used on this website
        are copyrighted. Only State Department photos are in the public domain.
      </p>
      <h2 tabIndex={0}>
        Use of U.S. State Department Seal, the U.S. Great Seal, and Other
        Official Insignia
      </h2>
      <p role="note" tabIndex={0}>
        U.S. State Department seals, the U.S. Great Seal, logos, and other
        official insignia may not be used or reproduced without written
        permission. Use of the Great Seal of the United States is governed by
        Public Law 91-651, Title 18 of the United States Code. This is a
        criminal statute with penal provisions, prohibiting certain uses of the
        Great Seal that would convey or reasonably be calculated to convey a
        false impression of sponsorship or approval by the Government of the
        United States or any department, agency, or instrumentality thereof.
      </p>
      <p role="note" tabIndex={0} onBlur={() => leavePage()}>
        Although the Secretary of State is custodian of the Seal, the Department
        of State has no authority to grant or withhold permission for use of
        reproductions, facsimiles, or likenesses of the Seal, or any part
        thereof. It depends on the circumstances in each case whether the
        particular use of the Seal would be improper and, as such, it is a
        function of the Department of Justice to determine whether any
        particular use violates the Statute. Consequently, the Department of
        State&apos;s policy has been to discourage use of the Great Seal, except
        when used for governmental or educational purposes, and the Department
        does not provide artwork for its use other than for official State
        Department material. Contact us if you have any questions on use of the
        Great Seal or other official insignia.
      </p>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="Copyright_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

CopyrightPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default CopyrightPage;
