import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/VietNam/communities1.png";
import image2 from "assets/images/CountryStories/VietNam/communities2.png";
import image3 from "assets/images/CountryStories/VietNam/communities3.png";
import image4 from "assets/images/CountryStories/VietNam/communities4.png";

import "../CountryPages.scss";

const Communities = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="communities"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            What Communities Are Currently Engaged in
          </header>
        </Grid>
        <Grid container size={12} sx={storyBody}>
          <Grid size={12}>
            <Typography>
              <List
                sx={{
                  pl: 5,
                  pr: 5,
                  pt: 0,
                  color: theme.color,
                  // listStyleType: "disc",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  Direct service delivery through out the clinical cascade: case
                  finding (outreach, testing, referrals), linkage to prevention
                  and treatment services (PrEP, ART, harm reduction), public
                  health cluster response (PHCR), site-level monitoring.
                </ListItem>
                <ListItem tabIndex={0}>
                  Above-site activities: sustainable financing for HIV
                  prevention services (social contracting), reducing stigma &
                  discrimination (evidence generation and proposing
                  interventions), communications (behavior change and demand
                  generation), above-site monitoring, and community dialogues.
                </ListItem>
                <ListItem tabIndex={0}>
                  Participation in planning and decision-making processes:
                  PEPFAR Country Operational Plan meetings and process, Global
                  Fund Country Coordinating Mechanism, many national and
                  international fora to discuss laws (e.g., HIV law,
                  gender-reaffirmation law), regulations, policies, and
                  guidelines.
                </ListItem>
              </List>
            </Typography>
          </Grid>
          <Grid container size={10} style={{ margin: "auto" }}>
            <Grid size={3}>
              <div className="image-frame">
                <img
                  style={{ width: "15vw" }}
                  src={image1}
                  alt="decorative image"
                />
              </div>
            </Grid>
            <Grid size={3}>
              <div className="image-frame">
                <img
                  style={{ width: "15vw" }}
                  src={image2}
                  alt="decorative image"
                />
              </div>
            </Grid>
            <Grid size={3}>
              <div className="image-frame">
                <img
                  style={{ width: "15vw" }}
                  src={image3}
                  alt="decorative image"
                />
              </div>
            </Grid>
            <Grid size={3}>
              <div className="image-frame">
                <img
                  style={{ width: "15vw" }}
                  src={image4}
                  alt="decorative image"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Communities;
