import React from "react";
import Grid from "@mui/material/Grid2";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Malawi/FullCircle.png";
import mapData from "assets/data/maps/Malawi/Malawi_6_Districts_2022_Nov.json";
import SMXMap2 from "components/Widgits/SMXMap2";
// import { mapOptions } from "./ImplementationMapData";
import "../CountryPages.scss";

const Implementation = () => {
  const mapOptions = {
    visualMap: {
      type: "piecewise",
      left: "left",
      draggable: true,
      top: "bottom",
      orient: "vertical",
      min: 0,
      max: 1,
      range: [0, 1],
      padding: 20,
      inRange: {
        color: ["#894465", "#267300"],
      },
      legend: {
        textStyle: {
          fontSize: 20,
        },
      },
      // inverse: true,
      // icon: "circle",
      showLabel: true,
      labelLayout: { hideOverlap: true },
      // precision: 20,
      align: "left",
      text: ["Districts"],
      // calculable: true,
      pieces: [
        { min: 0, max: 0, label: "Non-CLM District", color: "#894465" },
        { min: 1, max: 1, label: "CLM District", color: "#267300" },
      ],
    },
    tooltip: {
      trigger: "item",
      position: "top",
      formatter: function (params) {},
    },
    toolbox: {
      show: true,
      orient: "vertical",
      x: "right",
      y: "center",
      backgroundColor: "#ddd",
      color: "black",
      feature: {
        dataView: {
          readOnly: true,
        },
        restore: { show: true, title: "Restore Map", inactiveColor: "#ccc" },
        saveAsImage: { show: true },
      },
    },
    series: [
      {
        id: "Concordance",
        type: "map",
        roam: true,
        map: "USA",
        zoom: 1.25,
        animationDurationUpdate: 1000,
        universalTransition: false,
        label: {
          show: true,
          color: "black",
          fontSize: "0.6rem",
          fontWeight: 800,
          formatter: function (params) {
            return params.name.replace(" District", "");
          },
        },
        itemStyle: {
          borderWidth: 1.5,
          borderType: "solid",
          borderColor: "#000000",
        },
        data: [
          {
            name: "Balaka District",
            value: 0,
          },
          { name: "Blantyre District", value: 1 },
          { name: "Chikwawa District", value: 1 },
          { name: "Chiradzulu District", value: 0 },
          { name: "Chitipa District", value: 0 },
          { name: "Dedza District", value: 0 },
          { name: "Dowa District", value: 0 },
          { name: "Karonga District", value: 0 },
          { name: "Kasungu District", value: 0 },
          { name: "Likoma District", value: 0 },
          { name: "Lilongwe District", value: 1 },
          { name: "Machinga District", value: 0 },
          { name: "Mangochi District", value: 0 },
          { name: "Mchinji District", value: 0 },
          { name: "Mulanje District", value: 0 },
          { name: "Mwanza District", value: 0 },
          { name: "Mzimba District", value: 1 },
          { name: "Neno District", value: 0 },
          { name: "Nkhata Bay District", value: 0 },
          { name: "Nkhotakota District", value: 0 },
          { name: "Nsanje District", value: 0 },
          { name: "Ntcheu District", value: 0 },
          { name: "Ntchisi District", value: 0 },
          { name: "Phalombe District", value: 0 },
          { name: "Rumphi District", value: 0 },
          { name: "Salima District", value: 0 },
          { name: "Thyolo District", value: 1 },
          { name: "Zomba District", value: 1 },
        ],
      },
    ],
  };

  return (
    <Grid
      container
      id="implementation"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>Malawi CLM Implementation Program</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "5vh",
              height: "70vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid size={{ sm: 12, md: 12, lg: 6 }}>
              <SMXMap2
                mapFile={mapData}
                options={mapOptions}
                tabIndex={0}
                aria-label="Map of Malawi showing CLM and Non-CLM Districts."
              />
            </Grid>
            <Grid size={{ sm: 12, md: 12, lg: 6 }}>
              <div className="image-frame">
                <img
                  tabIndex={0}
                  src={image}
                  alt="Fig 2: CLM Implementation Cycle of activities from data collection, analysis and translation, engagement and dissemination, advocacy, and monitoring.  The cycle then repeats."
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Implementation;
