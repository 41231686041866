import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { selectTheme } from "stateMgmt/appSlice";
import { NavLink } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import logger from "../../../services/LoggingService";
import * as common from "common";
import "./CollapsibleSection.scss";

const s3path = common.getS3Url();

const CollapsibleSection = (props) => {
  const theme = useSelector(selectTheme);
  const [collapsed, setCollapse] = useState(false);
  let currentCollapsibleIcon = (
    <i className="far fa-plus-square collapsible-icon"></i>
  );

  useEffect(() => {
    if (!collapsed) {
      currentCollapsibleIcon = (
        <i className="far fa-plus-square collapsible-icon"></i>
      );
    } else {
      currentCollapsibleIcon = (
        <i className="far fa-minus-square collapsible-icon"></i>
      );
    }
  });

  const toggle = () => {
    setCollapse(!collapsed);
  };

  const keypressed = (e) => {
    if (e.keyCode === 32) {
      toggle();
      logger.debug("Open section ", e);
    }
  };

  return (
    <div>
      <div
        className="collapsible-outer__div"
        onClick={toggle}
        onKeyDown={(e) => keypressed(e)}
        tabIndex={0}
        onBlur={props.isEOF ? props.callback : null}
        role="button"
        aria-label={`${props.collapsibleSectionHeader}, press enter, then tab thru details, or press tab to continue.`}
      >
        {currentCollapsibleIcon}
        <div className="collapsible-title-text">
          <Typography role="heading">
            {props.collapsibleSectionHeader}
          </Typography>
        </div>
      </div>
      <Collapse in={collapsed}>
        {/* The following code iterates through the collapsibleSectionParagraphs and takes them from the JSON file into HTML <p> paragraphs.
              There is a special case where some internal links are referenced, which had to be done here and not in JSON file. */}
        {props.collapsibleSectionParagraphs.map(
          (sectionParagraph, sectionParagraphIndex) =>
            props.collapsibleSectionHeader ===
            "Where can I find more information on how these indicators are defined?" ? (
              <div className="collapse show" key={uuid()}>
                <Typography>
                  <p className="collapsible-paragraph">
                    The indicator reference sheets can be downloaded from the
                    list below.
                    <List dense sx={{ pl: 10, color: theme.linkColor }}>
                      <ListItemText>
                        <a
                          href=" https://datim.zendesk.com/hc/en-us/articles/360000084446-MER-Indicator-Reference-Guides"
                          target="_blank"
                        >
                          2018+ results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/MER_2017_Indicator_Reference_Sheets.zip`}
                        >
                          2017 results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/MER_2016_Indicator_Reference_Sheets.zip`}
                        >
                          2016 results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/MER_2015_Indicator_Reference_Sheets.zip`}
                        >
                          2015 results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/2013-2014_Indicator_Reference_Sheets.zip`}
                        >
                          2013-2014 results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/2009-2012_Indicator_Reference_Sheets.zip`}
                        >
                          2009-2012 results
                        </a>
                      </ListItemText>
                      <ListItemText>
                        <a
                          href={`${s3path}reference/2004-2008_Indicator_Reference_Sheets.zip`}
                        >
                          2004-2008 results
                        </a>
                      </ListItemText>
                    </List>
                  </p>
                  <p className="collapsible-paragraph">
                    In the indicator reference sheets, each indicator is
                    described in detail, including information regarding
                    definitions, methods of measurement, and precautions for
                    interpretation. Please note that for most indicators, there
                    are multiple reference sheets depending on the time period
                    in question. Please see the{" "}
                    <NavLink
                      style={{
                        color: theme.color,
                      }}
                      to="/dataSources"
                    >
                      Data Sources
                    </NavLink>{" "}
                    page for an explanation of PEPFAR’s reporting phases.
                  </p>
                </Typography>
              </div>
            ) : (
              <Typography key={uuid()}>
                <p className="collapsible-paragraph" tabIndex={0}>
                  {ReactHtmlParser(sectionParagraph)}
                </p>
              </Typography>
            ),
        )}
      </Collapse>
    </div>
  );
};

CollapsibleSection.propTypes = {
  collapsibleSectionParagraphs: PropTypes.any,
  collapsibleSectionHeader: PropTypes.string,
  isEOF: PropTypes.bool,
  callback: PropTypes.func,
};

export default CollapsibleSection;
