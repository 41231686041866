export const storyHeader = {
  height: "10vh",
  // marginTop: 2,
  // border: "1px solid red",
};
export const storyBody = {
  height: "80vh",
  width: "90vw",
  marginTop: 1,
  // border: "1px solid blue",
  overflow: "auto",
};
