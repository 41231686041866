import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
// import { selectapp } from "../stateMgmt/appSlice";
import { selectCurrentFYear } from "../stateMgmt/daaSlice";
import { v4 as uuid } from "uuid";
import { fetchTableData } from "common";
import inputData from "components/DAAMapPage/DaaMapGeoData.json";
import logger from "../services/LoggingService";

import "./factory.scss";

const DAATableGenerator = () => {
  logger.info("DAATableGenerator Being Created");
  // const theme = useSelector(selectapp).theme;
  const currentYear = useSelector(selectCurrentFYear);
  const [tableData, setTableData] = useState(null);
  // const [filteredData, setFilteredData] = useState(null);
  const hiddenDataCountries = new Map([
    ["FY2021", ["South Africa", "Mozambique", "Ukraine"]],
    ["FY2022", ["South Africa", "Mozambique", "Ukraine", "Vietnam"]],
  ]);

  const getFyIndicators = (year) => {
    return year === "FY2022"
      ? inputData.trackedIndicators[1]
      : inputData.trackedIndicators[0];
  };

  const StyledHeaderCell = () =>
    styled(TableCell)(() => ({
      [`&.${tableCellClasses.head}`]: {
        textAlign: "center",
        backgroundColor: "#cccccc",
        padding: "5px 0px 0px 15px",
      },
    }));

  const CreateDAATableCell = (color, ...props) =>
    styled(TableCell)(() => ({
      [`&.${tableCellClasses.body}`]: {
        ...props,
        fontSize: "0.5vw",
        textAlign: "center",
        borderColor: "#000000",
        backgroundColor: color,
      },
      "&:nth-of-type(1)": {
        textAlign: "left",
        fontSize: "0.6vw",
        width: 500,
      },
    }));

  const DAATableCell = CreateDAATableCell("#cccccc");

  const getBgColor = (disc) => {
    let color = "#FFFFFF";
    switch (disc.toLowerCase()) {
      case "fine":
        color = "#C7E5CC";
        break;

      case "coarse":
        color = "#FCEDBC";
        break;

      case "missing":
        color = "#F0C6CB";
        break;

      default:
        break;
    }

    return color;
  };

  const getDataColor = (data, isFirst) => {
    let color = "#FFFFFF";
    if (data.toLowerCase() === "no data") {
      color = "#F0C6CB";
    } else if (isFirst) {
      color = "#52B825";
    }
    return color;
  };

  const getCell = (data, color, span) => {
    const CustomTableCell = CreateDAATableCell(color);
    return span === undefined ? (
      <CustomTableCell sx={{ border: 1 }}>{data}</CustomTableCell>
    ) : (
      <CustomTableCell sx={{ border: 1 }} colSpan={span}>
        {data}
      </CustomTableCell>
    );
  };

  const HeaderCell = StyledHeaderCell();

  const getAverages = (tableData, fiscalYear) => {
    const valueMatrix = [];
    const totals = [];
    const divisors = [];
    Object.entries(tableData.rowData).forEach(([key, value]) => {
      const row = [];
      let index = 0;
      Object.entries(value).forEach(([key, value]) => {
        const target = value.value;
        if (index > 0) {
          const num = isNaN(parseFloat(target)) ? 0 : parseFloat(target);
          row.push(num);
        }
        index++;
      });
      valueMatrix.push(row);
    });

    valueMatrix.forEach((row) => {
      row.forEach((col, index) => {
        if (isNaN(totals[index])) {
          totals.push(col);
          // test.push(col);
        } else {
          totals[index] = totals[index] += col;
        }
        if (col > 0) {
          if (isNaN(divisors[index])) {
            divisors.push(1);
          } else {
            divisors[index] += 1;
          }
        }
      });
    });

    const averages = totals.map((val, index) => {
      let retVal;
      try {
        retVal = (val / divisors[index]).toFixed(2);
      } catch {
        retVal = 0;
      }
      return `${retVal}%`;
    });
    return averages;
  };

  const generateTableData = (fiscalYear, dataIn) => {
    let tmpData = {};
    // const tmpAverage = [];
    const tableData = {
      headerData: getFyIndicators(fiscalYear),
      averages: [],
      rowData: [],
    };
    const test = hiddenDataCountries.get(fiscalYear);

    dataIn?.forEach((data) => {
      const dat = data.properties.rowData[fiscalYear];
      if (!test.includes(data.properties.name)) {
        if (fiscalYear === "FY2021") {
          tmpData = {
            name: data.properties.rowData.name,
            col0: dat[0],
            col1: dat[1],
            col2: dat[2],
            col3: dat[3],
            col4: dat[4],
            col5: dat[5],
          };
        } else {
          tmpData = {
            name: data.properties.rowData.name,
            col0: dat[0],
            col1: dat[1],
            col2: dat[2],
            col3: dat[3],
            col4: dat[4],
            col5: dat[5],
            col6: dat[6],
            col7: dat[7],
          };
        }
        tableData.rowData.push(tmpData);
      }
    });

    tableData.averages = getAverages(tableData, fiscalYear);
    return tableData;
  };

  useEffect(() => {
    // logger.debug(theme);
    // logger.info(`DAATableGenerator useEffect called with year ${currentYear}`);
    const tmp = fetchTableData(inputData.features);
    // setFilteredData([...tmp]);

    const tableDat = generateTableData(currentYear, tmp);
    // logger.info("DAATableGenerator useEffect generated data:", tableDat);
    setTableData(tableDat);
  }, [currentYear]);

  const iterateRow = (obj) => {
    const retVal = { country: obj.name, columns: [] };
    // logger.info("ITERATING: ", obj.name);

    Object.entries(obj).forEach(([key, value]) => {
      let discordance;
      let val;
      let firstReport = false;

      Object.entries(value).forEach(([key, value]) => {
        if (key === "discordance") {
          discordance = value;
        } else if (key === "value") {
          val = value;
        } else if (key === "firstReport") {
          firstReport = value;
        }
      });

      retVal.columns.push({
        val,
        discordance,
        firstReport,
      });
    });
    retVal.columns = retVal.columns.slice(1);
    return retVal;
  };

  return (
    <Paper
      sx={{
        width: "100%",
        margin: "auto",
        overflow: "auto",
        padding: "10px",
      }}
    >
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <HeaderCell sx={{ backgroundColor: "#cccccc", border: 1 }}>
                Country
              </HeaderCell>
              {tableData?.headerData.map((col) => (
                <HeaderCell
                  key={uuid()}
                  colSpan={2}
                  sx={{
                    backgroundColor: "#cccccc",
                    border: 1,
                    textAlign: "center",
                  }}
                >
                  {col}
                </HeaderCell>
              ))}
            </TableRow>
            <TableRow key={uuid()}>
              <DAATableCell sx={{ border: 1 }} key={uuid()}></DAATableCell>
              {tableData?.averages.map((row) => getCell(row, "#757575", 2))}
            </TableRow>
            {tableData?.rowData.map((row) => (
              <TableRow key={uuid()}>
                {getCell(row.name, "#cccccc")}
                {iterateRow(row).columns.map((col) => (
                  <>
                    {getCell(col.discordance, getBgColor(col.discordance))}
                    {getCell(
                      isNaN(col.val) ? col.val : `${col.val}%`,
                      getDataColor(col.val, col.firstReport),
                    )}
                  </>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DAATableGenerator;
