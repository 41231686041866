import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const CommunityLed = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="pageTemplate"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>Community Led Monitoring (CLM) in Malawi</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Typography variant="body1">
            <List
              sx={{
                pl: 15,
                pr: 10,
                pt: 5,
                lineHeight: "1.5",
                color: theme.color,
                listStyleType: "disc",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem tabIndex={0}>
                Malawi has successfully achieved the UNAIDS global targets of
                95:95:95 (Spectrum Estimates 2024).
              </ListItem>
              <ListItem tabIndex={0}>
                Civil society organizations under the leadership of Malawi
                Network of AIDS Service Organization&apos;s (MANASO), have
                played a great part in achieving the target. ​
              </ListItem>
              <ListItem tabIndex={0}>
                MANASO is implementing a transformative Community Led Monitoring
                (CLM) project to identify and address gaps related to
                patient-centered services.
              </ListItem>
              <ListItem tabIndex={0}>
                CLM is a transformational process that encourages communities –
                especially beneficiaries, their families, and service providers
                – to make use of their data, experience, and ideas to inspire
                and sustain meaningful improvements across the HIV service
                continuum.
              </ListItem>
              <ListItem tabIndex={0}>
                CLM activities started in October 2020, with support from PEPFAR
                and focus on people living with HIV, adolescent girls & young
                women, key populations, and children. ​
              </ListItem>
              <ListItem tabIndex={0}>
                Mikolongwe Health Centre, located in Thyolo district in the
                Southern part of Malawi, is one of the health facilities where
                CLM is being implemented. It serves a vibrant community of
                32,372 people, including over 2,204 individuals living with HIV.
              </ListItem>
            </List>
          </Typography>
        </Grid>
      </article>
    </Grid>
  );
};

export default CommunityLed;
