import React from "react";
import Grid from "@mui/material/Grid2";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import image1 from "assets/images/CountryStories/CDI/CivilSociety-1.png";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const CivilSociety = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="civilSociety"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Civil society representatives voice concerns
          </header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid size={8}>
            <Typography
              tabIndex={0}
              variant="h3"
              sx={{ color: theme.color, padding: "2vh 5vw" }}
            >
              2008: Côte d’Ivoire’s Ministry of Health, Public Hygiene, and
              Universal Health Coverage (MSHPCMU) mandates free services for all
              people living with HIV (PLHIV) in public sector facilities.
            </Typography>
          </Grid>
          <Grid container>
            <Grid size={4}>
              <div
                className="bubble-div bubble-div-1"
                style={{ backgroundColor: "#BF2E1B" }}
              >
                <Typography
                  tabIndex={0}
                  variant="h1"
                  sx={{ color: "#ffffff", textAlign: "center" }}
                >
                  PLHIV face stigma and discrimination.
                </Typography>
              </div>
            </Grid>
            <Grid size={4}>
              <div
                className="bubble-div bubble-div-2"
                style={{ backgroundColor: "#6929C5" }}
              >
                <Typography
                  tabIndex={0}
                  variant="h1"
                  sx={{ color: "#ffffff", textAlign: "center" }}
                >
                  Prevention services are limited for key populations.
                </Typography>
              </div>
            </Grid>
            <Grid size={4}>
              <div
                className="bubble-div bubble-div-3"
                style={{ backgroundColor: "#006600" }}
              >
                <Typography
                  tabIndex={0}
                  variant="h1"
                  sx={{ color: "#ffffff", textAlign: "center" }}
                >
                  User fees remain as obstacles to accessing HIV services.
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid size={12}>
            <div className="image-frame">
              <img
                aria-label="Decorative image."
                id="CS-img1"
                src={image1}
                alt="decorative image"
                tabIndex={0}
              />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default CivilSociety;
