import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const RemediationTwo = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="remediationTwo"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Remediation Process</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "5vh",
            }}
          >
            <Grid size={12}>
              <Typography>
                <List
                  sx={{
                    pl: 15,
                    pr: 10,
                    pt: 5,
                    lineHeight: "1.75",
                    color: theme.color,
                    listStyleType: "disc",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    In December 2023, the CLM team followed up on the commitment
                    by Malawi AIDS Counseling and Resource Organization (MACRO).
                    It was established that MACRO had honored its commitment.
                    Since then, CLM data demonstrated improvements in waiting
                    times.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    The reduction in waiting times was acknowledged not only by
                    the recipients of care but also by the facility in-charge at
                    Mikolongwe.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    &quot;I can now confirm that with the coming in of
                    additional staff, and training of two HSAs our services have
                    greatly improved, mainly in the areas of data entry,
                    defaulter tracing and professional HIV and AIDS
                    counselling&quot; (Facility in Charge)
                  </ListItem>
                  <ListItem tabIndex={0}>
                    One recipient of care also added &quot;We have regained our
                    self-esteem, following the improved situation of receiving
                    HIV services without delays. We are motivated to come for
                    treatment and other services&quot;.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default RemediationTwo;
