import React from "react";
import Grid from "@mui/material/Grid2";
import image from "assets/images/CountryStories/Dreams/flowChart.png";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const Prevention = () => {
  return (
    <Grid
      container
      id="prevention"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title dreams-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            DREAMS Core Package of combination HIV prevention services for AGYW
          </header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid container sx={{ height: "70vh" }}>
            <div className="image-frame">
              <img
                tabIndex={0}
                src={image}
                style={{ width: "80%" }}
                alt="The DREAMS Core Package targets societal, structural, and individual factors increasing HIV risk for AGYW through layered, evidence-based interventions."
              />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Prevention;
