import React from "react";
import ReactECharts from "echarts-for-react";
import PropTypes from "prop-types";
import "./SMXChart.scss";

// const dummyCallback = (instance) => {
//   return null;
// };

const SMXChart = (props) => {
  return (
    <ReactECharts
      style={{ height: "70vh" }}
      option={props.options}
      onChartReady={props.chartCallback}
      opts={{ renderer: "canvas" }}
    />
  );
};

SMXChart.propTypes = {
  options: PropTypes.object.isRequired,
  chartCallback: PropTypes.func,
};

export default SMXChart;
