import React, { memo, useState, useEffect } from "react";
import { Navigation, Pagination, A11y, EffectFade, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ally from "ally.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/effect-fade";
import logger from "services/LoggingService";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import { selectswiper } from "stateMgmt/swiperSlice";
import { v4 as uuid } from "uuid";

/// ////////////////////// Story Pages /////////////////////////
// *************************************************************

// ------------------------------------------------------------
// Cote-diovoire
// ------------------------------------------------------------
import CivilSociety from "./pages/CDI/CivilSociety";
import Timeline from "./pages/CDI/Timeline";

// ------------------------------------------------------------
// Malawi
// ------------------------------------------------------------
import CommunityLed from "./pages/Malawi/CommunityLed";
import Implementation from "./pages/Malawi/Implementation";
import TheChallenge from "./pages/Malawi/TheChallenge";
import Remediation from "./pages/Malawi/Remediation";
import RemediationTwo from "./pages/Malawi/RemediationTwo";
import WaitingTime from "./pages/Malawi/WaitingTime";

// ------------------------------------------------------------
// VietNam
// ------------------------------------------------------------
import Engagement from "./pages/VietNam/Engagement";
import Communities from "./pages/VietNam/Communities";
import Access from "./pages/VietNam/Access";

// ------------------------------------------------------------
// DREAMS
// ------------------------------------------------------------
import Empowered from "./pages/Dreams/Empowered";
import Prevention from "./pages/Dreams/Prevention";

/// ////////////////////////////////////////////////////////////

let currentPage;

export const slides = [
  [Empowered, Prevention],
  [CivilSociety, Timeline],
  [
    CommunityLed,
    Implementation,
    TheChallenge,
    Remediation,
    RemediationTwo,
    WaitingTime,
  ],
  [Engagement, Communities, Access],
];

// ********************** CountryModal Component **********************
const CountryModal = memo(function CountryModal(props) {
  const theme = useSelector(selectapp).theme;
  const currentStoryNumber = useSelector(selectswiper).currentStoryNumber;
  const currentSlides =
    currentStoryNumber !== undefined ? [...slides[currentStoryNumber]] : [];
  const numSlides = currentSlides.length;
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const tabbableElements = ally.query.tabbable({
    context: ".CountryContainer",
    includeContext: true,
    strategy: "quick",
  });

  const pageHeaders = tabbableElements.filter((el) => {
    return el.className === "story-title" || el.className === "divider";
  });

  const setNextTab = () => {
    const direction =
      controlledSwiper.realIndex > controlledSwiper.previousRealIndex
        ? "forward"
        : "reverse";

    currentPage = direction === "forward" ? currentPage + 1 : currentPage - 1;
    if (currentPage === numSlides) {
      currentPage = numSlides - 1;
    } else if (currentPage < 0) {
      currentPage = 0;
    }

    const nextHeader = pageHeaders[currentPage];

    try {
      ally.element.focus(nextHeader);
    } catch {
      logger.error("FOCUS FAILED");
    }
  };

  useEffect(() => {
    currentPage = 0;
  });

  return (
    <div
      className="CountryContainer"
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.color,
      }}
    >
      <Swiper
        style={{
          "--swiper-navigation-color": "black",
          "--swiper-navigation-size": "35px",
        }}
        modules={[Navigation, Pagination, A11y, EffectFade, Keyboard]}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={false}
        // speed={700}
        slidesPerView={1}
        keyboard={{
          enabled: true,
        }}
        navigation
        pagination={{ type: "progressbar", clickable: true }}
        grabCursor={true}
        onSwiper={setControlledSwiper}
        onSlideChange={() => {
          setNextTab();
        }}
        onReachEnd={() => {}}
      >
        {currentSlides.map((slideContent, index) => (
          <SwiperSlide key={uuid()} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});
// ********************************************************************

export default CountryModal;
