import React from "react";
import daaData from "./DaaMapGeoData.json";
import DaaMap from "./DaaMap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectDaa } from "stateMgmt/daaSlice";

import "./DAAMapPage.scss";

const DAAMapChart = (props) => {
  const currentState = useSelector(selectDaa);

  return (
    <div id="MAP-CHART" data-testid="MAP-CHART" className="map-div">
      <DaaMap mapFile={daaData} countries={currentState.labels} />
      <div>
        <div data-testid="btnDiv" className="btnDiv">
          {currentState.buttonBar}
        </div>
      </div>
    </div>
  );
};

DAAMapChart.propTypes = {
  initialYear: PropTypes.string.isRequired,
};

export default DAAMapChart;
