import React, { useEffect } from "react";
import * as echarts from "echarts";
import PropTypes from "prop-types";
import "./SMXChart.scss";

const SMXMap2 = (props) => {
  let smxMap;
  echarts.registerMap("USA", props.mapFile, {});
  const options = props.options;

  function initMap() {
    smxMap = echarts.init(document.getElementById("smxMapDiv"));
    smxMap.setOption(options);

    smxMap.on("restore", function (params) {
      smxMap.setOption(options);
    });
  }

  useEffect(() => {
    initMap();
  }, []);

  return (
    <div
      role="presentation"
      aria-label="interactive map"
      id="smxMapDiv"
      style={{ width: "100%", height: "100%", border: "2px solid gray" }}
      //   className="daa-map-container"
    ></div>
  );
};

SMXMap2.propTypes = {
  options: PropTypes.object.isRequired,
  mapFile: PropTypes.object.isRequired,
};

export default SMXMap2;
