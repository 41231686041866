import { configureStore, combineReducers } from "@reduxjs/toolkit";
import daaReducer from "stateMgmt/daaSlice";
import appReducer from "stateMgmt/appSlice";
import swiperReducer from "stateMgmt/swiperSlice";
import { reduxLogger, crashReporter } from "./middleware/loggerMiddleware";

const rootReducer = combineReducers({
  daaState: daaReducer,
  appState: appReducer,
  swiperState: swiperReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(reduxLogger)
      .concat(crashReporter),
});

export default store;
