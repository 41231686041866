import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStoryNumber: 0,
};

export const swiperSlice = createSlice({
  name: "swiperState",
  initialState,
  reducers: {
    updateSlide: (state, action) => {
      const newState = {
        ...state,
        currentStoryNumber: action.payload.currentStoryNumber,
      };
      return newState;
    },
  },
});

export const selectswiper = (state) => state.swiperState;
export const { updateSlide } = swiperSlice.actions;
export default swiperSlice.reducer;
