import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Malawi/remediation.png";

import "../CountryPages.scss";

const Remediation = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="remediation"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Remediation Process</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "5vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid size={{ sm: 12, md: 12, lg: 6 }}>
              <Typography>
                <List
                  sx={{
                    px: 10,
                    color: theme.color,
                    listStyleType: "disc",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    In August 2023, the CLM team brought the matter to the
                    attention of facility-level providers and PEPFAR
                    implementing partners.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    The CLM team and service providers together conducted a root
                    cause analysis to identify the cause of the problem.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    It was established that the long waiting hours were due to
                    inadequate staff at the facility.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    At the time of data collection, the facility had only one
                    HIV Diagnostic Assistant (HDA) responsible for HIV testing,
                    counselling, and collecting viral load samples.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    This led to extended waiting times for the recipients of
                    care.  
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid size={{ sm: 12, md: 12, lg: 6 }}>
              <div className="image-frame">
                <img
                  tabIndex={0}
                  src={image}
                  alt="Picture 4 depicts a district level engagement meeting involving key stakeholders responsible for decision making on health matters in Thyolo district."
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Remediation;
