import React from "react";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import PrimaryDataFigure from "./PrimaryDataFigure/PrimaryDataFigure";
import primaryDataFiguresConfigBackup from "assets/configs/primary-data-figures-config.json";
import Typography from "@mui/material/Typography";
import { v4 as uuid } from "uuid";
import "./ProgramResultsFiguresRow.scss";

const ProgramResultsFiguresRow = () => {
  let primaryDataFigures = null;
  const theme = useSelector(selectapp).theme;

  primaryDataFigures = primaryDataFiguresConfigBackup.figures.map(
    (figure, index) =>
      index === 0 ||
      index === primaryDataFiguresConfigBackup.figures.length - 1 ? (
        <div
          className="col-xs-12 col-sm-12 col-md-4 col-lg-4 primaryDataFigures__outer_column"
          key={uuid()}
        >
          <PrimaryDataFigure
            index={index}
            value={figure.value}
            previousYearValue={figure.previousYearValue}
            description={figure.description}
            previousYearDescription={figure.previousYearDescription}
            chartTitle={figure.chartTitle}
            chartSubTitle={figure.chartSubTitle}
            chartDataPath={figure.chartDataPath}
          />
        </div>
      ) : (
        <div
          className="col-xs-12 col-sm-12 col-md-4 col-lg-4 primaryDataFigures__inner_column"
          key={uuid()}
        >
          <PrimaryDataFigure
            value={figure.value}
            previousYearValue={figure.previousYearValue}
            description={figure.description}
            previousYearDescription={figure.previousYearDescription}
            chartTitle={figure.chartTitle}
            chartSubTitle={figure.chartSubTitle}
            chartDataPath={figure.chartDataPath}
          />
        </div>
      ),
  );

  return (
    <div className="mb-4">
      <div
        className="row h-25"
        style={{
          color: theme.color,
        }}
      >
        <div className="col" style={{ marginBottom: "5px" }}>
          <Typography
            variant="h1"
            id="program_results_header"
            tabIndex={1}
            aria-label="Landing page header. Program Results Achieved Through PEPFAR Support."
            sx={{
              color: theme.linkColor,
            }}
          >
            Program Results Achieved Through PEPFAR Support
          </Typography>
        </div>
      </div>
      <div className="row primaryDataFigures__row no-gutters small-gutters">
        {primaryDataFigures}
      </div>
    </div>
  );
};

export default ProgramResultsFiguresRow;
