import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Malawi/theChallenge.png";

import "../CountryPages.scss";

const TheChallenge = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="theChallenge"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Challenge</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "5vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid size={{ sm: 12, md: 12, lg: 5 }}>
              <Typography variant="body1">
                <List
                  sx={{
                    px: 10,
                    lineHeight: "1.75",
                    color: theme.color,
                    listStyleType: "disc",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    For many years, the recipients of care (RoC) at Mikolongwe
                    Health Centre faced a significant barrier to getting care.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    RoC had long waiting times before accessing HIV services.
                    The challenges were compounded by the fact that recipients
                    did not know how or to whom they should voice their
                    concerns.
                  </ListItem>
                  <ListItem tabIndex={0}>
                    In July 2023, RoC through a CLM routine focus group
                    discussion raised their concern.
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid size={{ sm: 12, md: 12, lg: 7 }}>
              <div className="image-frame">
                <img
                  tabIndex={0}
                  src={image}
                  alt="Picture 3 depicts a CLM Community Monitor conducting a Focus Group Discussion with women living with HIV at Mikolongwe Health Centre."
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default TheChallenge;
