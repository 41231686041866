import React, { useEffect } from "react";
import * as echarts from "echarts";
import { getDaaToolTip } from "./DaaToolTip";
import PropTypes from "prop-types";
import logger from "services/LoggingService";
import "./DAAMapPage.scss";

let countries;
let indicatorIndex;
let theMap;

const mapOptions = {
  backgroundColor: "lightblue",
  visualMap: {
    type: "piecewise",
    left: "left",
    top: "bottom",
    orient: "vertical",
    min: 0,
    max: 100,
    range: [0, 100],
    padding: 20,
    inRange: {
      color: ["gray", "red", "orange", "yellow", "lightgreen", "green"],
    },
    inverse: true,
    icon: "circle",
    showLabel: true,
    labelLayout: { hideOverlap: true },
    precision: 20,
    align: "left",
    text: ["Concordance"],
    calculable: true,
    // backgroundColor: "#ddd",
    pieces: [
      { min: -1, max: 0, label: "<0.00", color: "gray" },
      { min: 0.1, max: 29.99, label: "0.00 - 29.99", color: "red" },
      { min: 30, max: 49.99, label: "30.00-49.99", color: "orange" },
      { min: 50, max: 69.99, label: "50.00-69.99", color: "yellow" },
      { min: 70, max: 89.99, label: "70.00-89.99", color: "lightgreen" },
      { min: 90, label: ">90", color: "green" },
    ],
  },
  tooltip: {
    trigger: "item",
    position: "top",
    formatter: function (params) {
      const tooltipDat = params.data.tracked
        ? {
            name: params.data.name,
            inputValue: params.data.value,
            inputTotal: params.data.total,
            currentIndicatorIndex: indicatorIndex,
            currentIndicator: params.data.indicators[indicatorIndex],
          }
        : {};

      return params.data.tracked
        ? getDaaToolTip(tooltipDat)
        : `<p style='text-align: center;margin: 0px'>${params.data.name}</p><p style='text-align: center;margin: 0px'>(Non-Participant)</p>`;
    },
  },
  toolbox: {
    show: true,
    orient: "vertical",
    x: "right",
    y: "center",
    backgroundColor: "#ddd",
    color: "black",
    feature: {
      restore: { show: true, title: "Restore Map", inactiveColor: "#ccc" },
      saveAsImage: { show: true },
    },
  },
  series: [
    {
      id: "Concordance",
      type: "map",
      roam: true,
      map: "USA",
      zoom: 2,
      aspectScale: 0.95,
      // center: ["50vmin", "50vmin"],
      animationDurationUpdate: 1000,
      universalTransition: false,
      label: {
        show: false,
        formatter: function (params) {
          let retVal = "";

          if (countries.includes(params.name)) {
            switch (params.value) {
              case null:
                retVal = params.name;
                break;

              case -1:
                retVal = `${params.name}\n(No Data)`;
                break;

              default:
                retVal = `${params.name}\n${params.value}%`;
            }
          }
          return retVal;
        },
      },
      data: [],
    },
  ],
};

export function drawDaaMap(buffer, index) {
  logger.debug(`drawDaaMap called with index ${index}`, buffer);
  indicatorIndex = index;
  mapOptions.series[0].data = buffer;
  mapOptions.countries = countries;
  theMap.setOption(mapOptions);
}

export function displayLabels(show) {
  mapOptions.series[0].label.show = show;
  theMap.setOption(mapOptions);
}

export function initMap() {
  theMap = echarts.init(document.getElementById("themap"));
  theMap.setOption(mapOptions);

  theMap.on("restore", function (params) {
    theMap.setOption(mapOptions);
  });
}

const DaaMap = (props) => {
  countries = [...props.countries];
  echarts.registerMap("USA", props.mapFile, {});

  useEffect(() => {
    initMap();
  }, []);

  return (
    <div
      role="presentation"
      aria-label="interactive map"
      id="themap"
      className="daa-map-container"
    ></div>
  );
};

DaaMap.propTypes = {
  mapFile: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};
export default DaaMap;
