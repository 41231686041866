import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { chartColors } from "components/CountryStories/charts";
import SMXChart from "components/Widgits/SMXChart";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import { roundNumber, getChartTitleSize, getChartFontSize } from "common";
import logger from "services/LoggingService";

import "../CountryPages.scss";

const WaitingTime = () => {
  const theme = useSelector(selectapp).theme;
  let theChart = null;

  const chartOptions = {
    title: {
      text: "Change in Waiting Time at Mikolongewe Health Centre",
      subtext: "",
      left: "center",
      textStyle: {
        fontSize: getChartTitleSize(),
        color: theme.color,
      },
      subtextStyle: {
        color: theme.color,
      },
    },
    aria: {
      show: true,
      label: {
        description:
          "Steady decrease in waiting time from almost two hours in FY24 Q1 to 47 minutes in FY24 Q4, following deployment of HIV Diagnostic Assistant (HDA) and Treatment Supporter.",
      },
    },
    color: chartColors,
    grid: { show: true, containLabel: true },
    xAxis: {
      name: "QUARTER",
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        padding: 10,
        color: "black",
        fontSize: getChartFontSize(),
        fontWeight: 600,
        rotate: 0,
      },
      data: ["Q1 (Oct-Dec 23)", "Q3 (Apr-Jun 24)", "Q4 (Jul-Sep 24)"],
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        align: "center",
        padding: [50, 0, 0, 0],
      },
      nameLocation: "middle",
    },
    yAxis: {
      name: "TIME",
      type: "value",
      axisLabel: {
        show: true,
        padding: 10,
        color: "black",
        fontSize: getChartTitleSize(),
        fontWeight: 600,
        formatter: function (params) {
          return roundNumber(params, 2).toString().replace(".", ":");
        },
      },
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        align: "center",
        padding: [0, 0, 60, 0],
      },
      nameLocation: "middle",
    },
    series: [
      {
        data: [1.59, 1.15, 0.47],
        label: {
          show: true,
          position: "top",
          fontWeight: "bold",
          fontSize: getChartFontSize(),
          formatter: function (params) {
            return params.value.toString().replace(".", ":");
          },
        },
        type: "bar",
        barWidth: "20%",
        itemStyle: {
          color: "#07607F",
          borderWidth: 2,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#000000",
          shadowColor: "#5470c6",
        },
        emphasis: {
          itemStyle: {
            color: "green",
          },
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      extraCssText: "box-shadow: 4px 6px 0px rgba(10, 10, 10, 0.3)",
    },
    center: ["100%", "50%"],
  };

  const setFontSize = () => {
    if (theChart) {
      theChart.setOption({
        title: {
          textStyle: {
            fontSize: getChartTitleSize(),
          },
        },
      });
    }
  };

  const updateSize = () => {
    logger.debug("updateSize called", theChart);
    logger.debug("Proposed size: ", getChartTitleSize());
    setFontSize();
  };

  useEffect(() => {
    // myChart.setOption(chartOptions);
    window.addEventListener("resize", updateSize);
  });

  const chartCallback = (param) => {
    logger.debug(`chart initialized.`, param);
    logger.debug("Proposed size: ", getChartTitleSize());

    theChart = param;
    setFontSize();
  };

  return (
    <Grid
      container
      id="waitingTime"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Outcome</header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <div id="myChart" className="chart">
            <SMXChart
              options={chartOptions}
              chartCallback={chartCallback}
              tabIndex={0}
              aria-label="Chart showing change in waiting time at Mikolongewe Health Centre."
            />
          </div>
          <Grid size={6}>Note: Data collection was not done in Q2</Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default WaitingTime;
